export class PageLink {
    id;
    title;
    url;

    constructor(id,title, url) {
        this.id = id;
        this.title = title;
        this.url = url;
    }
}