export class SiteModel {
    name;
    description;
    image;

    constructor(name, description, image) {
        this.name = name;
        this.description = description;
        this.image = image;
    }
}