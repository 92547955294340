export class SideNavigationLink {
    id;
    title;
    order;
    page;

    constructor(id,title, order, page) {
        this.id = id;
        this.title = title;
        this.order = order;
        this.page = page
    }
}